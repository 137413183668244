<template>
    <div class="px-2 px-lg-3 py-1 py-lg-2 border" style="background-color: cornsilk;" v-if="!readOnly && dsObjects.state._isLoaded" >
        <div v-for="(item,index) in dsAssetsObjects.data" class="row pb-3" :class="{'border-top':index>0}">
            <label class="text-uppercase d-block" :class="{'pt-2':index>0}">Object Type {{item.ObjectType}}</label>
            <h5 class="mb-1">{{ item.Name }}</h5>
            <template v-for="(itemRF, indexRF) in dsRequiredFields.data.filter(x=>x.ObjectType_ID===item.ObjectType_ID)">
                <div class="col-3">
                    <label>{{ itemRF.FieldName }}</label>
                    <input v-model="item[itemRF.FieldName]" class="form-control form-control-sm">
                </div>
            </template>

            <div class="my-3">
                <Properties  :readOnly="false" :itemId="item.ID" parentFieldName="Object_ID" viewName="aviw_Assets_ObjectsPropertiesNT" uniqueTable="atbl_Assets_ObjectsProperties" ref="properties"></Properties>
            </div>

            <label class="mb-0  col-12 fw-bold" v-if="getDocuments(item.ID).length">{{$t("Documents")}}</label>
            <template v-for="(item2,index2) in getDocuments(item.ID)" class="col-12">
                <small > <a style="min-width:60px" :href="`/document?ID=${item2.Document_ID}`" class="d-inline-block">{{ item2.Document_ID }}</a>  <span class="text-muted" > {{ item2.Name }} {{ item2.Title }}</span></small>
            </template>
            
        </div>
    </div>
</template>


<script setup>   
    import { ref, defineProps, popScopeId } from 'vue';
    import { getOrCreateDataObject } from 'o365-dataobject';

    import Properties from 'workflow.libraries.vue.components.Properties.vue';

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly:Boolean,
        objects:Object
    });
    
    const counter = ref(0);

    const dsObjects = getOrCreateDataObject({
        id: 'dsCompObjects',
        viewName: 'aviw_Scope_ItemsObjects',
        maxRecords: -1,
        distinctRows: false,
        fields:
             [{name: "Object_ID", },
             {name: "ID", },
             {name: "Object", type: "string" },
             {name: "ObjectType", type: "string" },
             {name: "Name", type: "string" },
             {name: "ObjectDescription", type: "string" },
             {name: "ObjectType_ID", type: "string", sortOrder:1, sortDirection:"desc" },]
    });

    if(!props.readOnly){
        dsObjects.recordSource.whereClause = "Item_ID =" + props.itemId;
        dsObjects.load();
    }
    
    props.objects?.on("AfterSave",()=>{
        dsObjects.load();
    });

    props.objects?.on("AfterDelete",()=>{
        dsObjects.load();
    });

    const dsRequiredFields = getOrCreateDataObject({
        id: 'dsRequiredFields',
        viewName: 'atbv_Assets_ObjectTypesFieldRequirements',
        maxRecords: -1,
        distinctRows: false,
        fields:
             [{name: "FieldName"}, {name: "ObjectType_ID"}]

    });

    const dsAssetsObjects = getOrCreateDataObject({
        id: 'dsAssetsObjects',
        viewName: 'aviw_Assets_Objects',
        uniqueTable: 'atbv_Assets_Objects',
        maxRecords: -1,
        distinctRows: false,
        allowUpdate: true,
        fields:
             [{name: "ID", type: "number",  },
             {name: "Name", type: "string", "sortOrder": 1, "sortDirection": "desc"},
             {name: "Description", type: "string" },
             {name: "ObjectType", type: "string" },
             {name: "ObjectType_ID", type: "number" },
             {name: "OrgUnit", type: "string" }]
    });

    const dsObjectDocuments = getOrCreateDataObject({
        id: "dsObjectDocuments",
        viewName: "aviw_Arena_ObjectsDocRequirements",
        distinctRows: false,
        uniqueTable: null,
        allowUpdate: false,
        allowInsert: false,
        allowDelete: false,
        appendData: false,
        selectFirstRowOnLoad: false,
        fields: [
            {name: "PrimKey"},
            {name: "Document_ID"},
            {name: "Name"},
            {name: "Title"},
            {name: "DocumentType"},
            {name: "ObjectType"},
            {name: "Object_ID"},
            {name: "ID"}
        ],
        clientSideHandler: false,
        maxRecords: -1,
        dynamicLoading: false,
        whereClause: "Document_ID IS NOT NULL AND Obsolete IS NULL",
        filterString: null,
        disableAutoLoad: false,
        optimisticLocking: false

    });


    dsObjects.on("DataLoaded", ()=>{
        if(dsObjects.data.length){
            let whereClause = "", whereClause2 = "", prevObjectType_ID;
            dsObjects.data.forEach(function(item, index){
                if(prevObjectType_ID != item.ObjectType_ID){
                    if(!whereClause){
                        whereClause += item.ObjectType_ID
                    }else{
                        whereClause += ", " + item.ObjectType_ID
                    }
                }
                prevObjectType_ID = item.ObjectType_ID

                if(!whereClause2){
                    whereClause2 += item.Object_ID
                }else{
                    whereClause2 += ", " + item.Object_ID
                }

            });
            dsRequiredFields.recordSource.whereClause = "ObjectType_ID IN(" + whereClause + ")";
            dsRequiredFields.load();

            dsObjectDocuments.recordSource.whereClause = "Object_ID IN(" + whereClause2 + ") AND Document_ID IS NOT NULL AND Obsolete IS NULL";
            dsObjectDocuments.load();
            
        }
    });

    dsRequiredFields.on("DataLoaded", ()=>{
        dsRequiredFields.data.forEach(function(item, index){
            dsAssetsObjects.fields.push({name:item.FieldName})
        });
        let whereClause = "";

        dsObjects.data.forEach(function(item, index){
            if(!whereClause){
                whereClause += item.Object_ID
            }else{
                whereClause += ", " + item.Object_ID
            }
        });
        
        dsAssetsObjects.recordSource.whereClause = "ID IN(" + whereClause + ")"; 
        dsAssetsObjects.load()
    });

    function getDocuments(id){
         return dsObjectDocuments.data.filter(x=>x.Object_ID==id);
    }

    var vTimeout = null;
    dsAssetsObjects.on("FieldChanged", (x)=>{
        if(vTimeout)
            clearTimeout(vTimeout);

        vTimeout = setTimeout(()=>{
            //dsAssetsObjects.save();
        }, 400)
    })


</script>